import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CoreTracking } from "../../../../models/CoreTracking";
import ModalComponent from '../../../misc/ModalComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InactivateGoogleUserTracking, TrackingOperationState } from "../../../../store/TrackingOperationAction";
import { EmptyRequestState } from "../../../../models/RequestState";
import StatusCode from "../../../../util/StatusCode";
import Loading from "../../../misc/Loading";
import { getErrorObject } from "../../../../models/ErrorObject";
import errorMessageDictionaries from "../../../../util/ErrorMessageDictionaries";

interface InactivateGoogleUserTrackingWizardState {
    trackingOperation: TrackingOperationState
}

interface InactivateGoogleUserTrackingWizardProps {
    tracking: CoreTracking
    close: (success: boolean) => void
}

const modalTitle = 'Inaktivera Google användar koppling';

const InactivateGoogleUserTrackingWizard: React.FC<InactivateGoogleUserTrackingWizardProps> = (props) => {
    const dispatch = useDispatch();
    const [wizardStep, setWizardStep] = useState<'confirm' | 'done'>('confirm');
    const inactivateGoogleUserTracking = useSelector<InactivateGoogleUserTrackingWizardState, EmptyRequestState>(state => state.trackingOperation.inactivateGoogleUserTracking)

    const confirmInput: React.MouseEventHandler<HTMLButtonElement> = (_) => {
        InactivateGoogleUserTracking(props.tracking.trackingId)(dispatch);
        setWizardStep('done');
    }

    const close = (success: boolean) => {
        setWizardStep('confirm');
        props.close(success);
    }

    switch (wizardStep) {
        case 'confirm': {
            return <ModalComponent
                isOpen={true}
                toggleModal={() => close(false)}
                header={modalTitle}
                update={confirmInput}
                confirm="Bekräfta"
                cancel="Avbryt">
                <p><FontAwesomeIcon title="WARNING" color="#fc1" icon={['fas', 'triangle-exclamation']} /> Flowsync kommer nu sluta att hantera Google kontot med id: {props.tracking.sourceSystemId}. Ansvaret för att inaktivera och radera detta konto tillhör inte längre Flowsync eller Eventful.</p>
            </ModalComponent>
        }
        case 'done': {
            if (inactivateGoogleUserTracking.code === StatusCode.COMPLETE) {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(true)}
                    header={modalTitle}
                    cancel="Stäng">Inaktivering genomförd.</ModalComponent>
            } else if (inactivateGoogleUserTracking.code === StatusCode.ERROR) {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt"><FontAwesomeIcon title="ERROR" color="red" icon={['fas', 'triangle-exclamation']} /> {getErrorObject(inactivateGoogleUserTracking, errorMessageDictionaries.sv_SE).description}</ModalComponent>
            } else {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt">
                    <Loading />
                </ModalComponent>;
            }
        }
    }
}

export default InactivateGoogleUserTrackingWizard;
