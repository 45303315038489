import React, { useEffect, useState } from "react";
import { CoreTracking } from "../../../../models/CoreTracking";
import { useDispatch, useSelector } from "react-redux";
import { ClearRestoreGoogleUserTracking, RestoreGoogleUserTracking, TrackingOperationState } from "../../../../store/TrackingOperationAction";
import ModalComponent from "../../../misc/ModalComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatusCode from "../../../../util/StatusCode";
import Loading from "../../../misc/Loading";
import { RequestState } from "../../../../models/RequestState";
import { CoreGoogleUserTrackingConflict } from "../../../../models/CoreTrackingOperation";
import { Link } from "react-router-dom";
import { ExportGoogleUserTrackingMetadata } from "../../../../models/TrackingMetadata";
import { getErrorObject } from "../../../../models/ErrorObject";
import errorMessageDictionaries from "../../../../util/ErrorMessageDictionaries";
import GoogleUserTrackingOperationsValidationResult from "./GoogleUserTrackingOperationsValidationResult";

interface RestoreGoogleUserTrackingWizardState {
    trackingOperation: TrackingOperationState
}

interface RestoreGoogleUserTrackingWizardProps {
    tracking: CoreTracking
    close: (success: boolean) => void
}

const modalTitle = 'Återställ Google användar koppling';

const RestoreGoogleUserTrackingWizard: React.FC<RestoreGoogleUserTrackingWizardProps> = (props) => {
    const dispatch = useDispatch();
    const metadata = JSON.parse(props.tracking.metadata) as ExportGoogleUserTrackingMetadata;
    const [wizardStep, setWizardStep] = useState<'confirm' | 'done'>('confirm');
    const restoreGoogleUserTracking = useSelector<RestoreGoogleUserTrackingWizardState, RequestState<CoreGoogleUserTrackingConflict[]>>(state => state.trackingOperation.restoreGoogleUserTracking)

    useEffect(() => {
        RestoreGoogleUserTracking(props.tracking.trackingId, true)(dispatch);
        return () => ClearRestoreGoogleUserTracking()(dispatch);
    }, [props, dispatch]);

    const confirmInput: React.MouseEventHandler<HTMLButtonElement> = (_) => {
        RestoreGoogleUserTracking(props.tracking.trackingId, false)(dispatch);
        setWizardStep('done');
    }

    const close = (success: boolean) => {
        setWizardStep('confirm');
        props.close(success);
    }

    switch (wizardStep) {
        case 'confirm': {
            if (restoreGoogleUserTracking.code === StatusCode.COMPLETE) {
                if (restoreGoogleUserTracking.data.length === 0)
                {
                    return <ModalComponent
                        isOpen={true}
                        toggleModal={() => close(false)}
                        header={modalTitle}
                        update={confirmInput}
                        confirm="Bekräfta"
                        cancel="Stäng">
                        <p><FontAwesomeIcon title="OK" color="green" icon={['fas', 'check-circle']} /> Inga fel upptäcktes, vänligen bekräfta återställning.</p>
                        <p><FontAwesomeIcon title="WARNING" color="#fc1" icon={['fas', 'triangle-exclamation']} /> Verifiera att e-postadressen '{metadata.PrimaryEmail}' inte är upptagen i Google eller att den gäller för konto med id: {props.tracking.sourceSystemId}.</p>
                    </ModalComponent>
                }

                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt">
                    <p><FontAwesomeIcon title="ERROR" color="red" icon={['fas', 'triangle-exclamation']} /> Konflikter upptäcktes:</p>
                    <GoogleUserTrackingOperationsValidationResult googleId={props.tracking.sourceSystemId} primaryEmail={metadata.PrimaryEmail} conflicts={restoreGoogleUserTracking.data} />
                </ModalComponent>;
            } else if (restoreGoogleUserTracking.code === StatusCode.ERROR) {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt"><FontAwesomeIcon title="ERROR" color="red" icon={['fas', 'triangle-exclamation']} /> {getErrorObject(restoreGoogleUserTracking, errorMessageDictionaries.sv_SE).description}</ModalComponent>
            } else {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt">
                    <Loading />
                </ModalComponent>;
            }
        }
        case 'done': {
            if (restoreGoogleUserTracking.code === StatusCode.COMPLETE) {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(true)}
                    header={modalTitle}
                    cancel="Stäng">Livscykelhantering återställd.</ModalComponent>
            } else if (restoreGoogleUserTracking.code === StatusCode.ERROR) {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt"><FontAwesomeIcon title="ERROR" color="red" icon={['fas', 'triangle-exclamation']} /> {getErrorObject(restoreGoogleUserTracking, errorMessageDictionaries.sv_SE).description}</ModalComponent>
            } else {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt">
                    <Loading />
                </ModalComponent>;
            }
        }
    }
}

export default RestoreGoogleUserTrackingWizard;
