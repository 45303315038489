interface ErrorMessageDictionaries {
    sv_SE: ErrorMessageDictionary
}

export interface ErrorMessageDictionary {
    __default: string,
    [key: string]: string,
}

const swedishErrorMessageDictionary: ErrorMessageDictionary = {
    __default: 'Något gick fel',
    UnmanagedGoogleAccount: 'Kontot har aldrig hanterat av en Google kontosync i Flowsync och får därför inte redigeras.',
    ModifiyingTrackingInLifecycleManagementNotAllowed: 'Det är inte tillåtet att redigera kontots koppling när det håller på att livscykelhanteras.',
    ModifiyingInactiveTrackingNotAllowed: 'Det är inte tillåtet att redigera en inaktiv källsystemskoppling.',
    InactivationOfInactiveTrackingNotAllowed: 'Det är inte tillåtet att inaktivera en redan inaktiv koppling till ett källsystem.',
    RestoreActiveTrackingNotAllowed: 'Det är inte tillåtet att återställa livscykelhanteringen för en källsystemskoppling som är aktiv.',
    RestoreTrackingForInactiveObjectNotAllowed: 'Det är inte tillåtet att återställa livscykelhanteringen mot ett källsystem när Flowsync objektet är inaktivt. Den enda effekten av detta är att Flowsync försöker påbörja livscykelhanteringen igen och kommer då troligtvis att misslyckas för att objektet i källan inte längre bör existera.',
    MappingToSameSourceIdentifierNotAllowed: 'Det är inte tillåtet att koppla om till samma id som redan är kopplat.'
};

const errorMessageDictionaries: ErrorMessageDictionaries = {
    sv_SE: swedishErrorMessageDictionary
}

export default errorMessageDictionaries;