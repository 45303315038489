import React, { useState } from 'react';
import { Nav, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CoreTracking } from '../../../../models/CoreTracking';
import MapGoogleUserTrackingWizard from './MapGoogleUserTrackingWizard';
import UpdateGoogleUserTrackingWizard from './UpdateGoogleUserTrackingWizard';
import RestoreGoogleUserTrackingWizard from './RestoreGoogleUserTrackingWizard';
import InactivateGoogleUserTrackingWizard from './InactivateGoogleUserTrackingWizard';

enum GoogleUserTrackingOperation {
    None = 0,
    Edit = 1,
    Inactivate = 2,
    Restore = 3,
    Map = 4,
}

interface GoogleUserTrackingOperationsMenuProps {
    tracking: CoreTracking
    onClose?: (success: boolean) => void
}

const GoogleUserTrackingOperationsMenu: React.FC<GoogleUserTrackingOperationsMenuProps> = (props) => {
    const [action, setAction] = useState(GoogleUserTrackingOperation.None);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    if (!isGoogleUserTracking(props.tracking)) {
        return null;
    }

    const close = (success: boolean) => {
        setAction(GoogleUserTrackingOperation.None);

        if (props.onClose) {
            props.onClose(success);
        }
    }

    return <React.Fragment>
        <Nav className="right">
            <Dropdown nav isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                <DropdownToggle nav style={{ display: 'inline' }}>
                    <FontAwesomeIcon icon="ellipsis" className="icon" title="Tracking actions" />
                </DropdownToggle>
                <DropdownMenu>
                    {props.tracking.active && <DropdownItem onClick={() => setAction(GoogleUserTrackingOperation.Map)}>Koppla om</DropdownItem>}
                    {props.tracking.active && <DropdownItem onClick={() => setAction(GoogleUserTrackingOperation.Edit)}>Redigera</DropdownItem>}
                    {props.tracking.active && <DropdownItem onClick={() => setAction(GoogleUserTrackingOperation.Inactivate)}>Inaktivera</DropdownItem>}
                    {!props.tracking.active && <DropdownItem onClick={() => setAction(GoogleUserTrackingOperation.Restore)}>Återställ</DropdownItem>}
                </DropdownMenu>
            </Dropdown>
        </Nav>
        {action === GoogleUserTrackingOperation.Map && <MapGoogleUserTrackingWizard tracking={props.tracking} close={close} />}
        {action === GoogleUserTrackingOperation.Edit && <UpdateGoogleUserTrackingWizard tracking={props.tracking} close={close} />}
        {action === GoogleUserTrackingOperation.Inactivate && <InactivateGoogleUserTrackingWizard tracking={props.tracking} close={close} />}
        {action === GoogleUserTrackingOperation.Restore && <RestoreGoogleUserTrackingWizard tracking={props.tracking} close={close} />}
    </React.Fragment>;
}

export const isGoogleUserTracking = (tracking: CoreTracking): boolean => {
    if (tracking.sourceName !== 'Google') {
        return false;
    }

    switch (tracking.sourceObjectType) {
        case 'StaffUser': return true;
        case 'StudentUser': return true;
        default: return false;
    }
}

export default GoogleUserTrackingOperationsMenu;
